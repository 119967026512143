<template>
  <div class="container-page-politicas">
    <div class="header-page-politicas">
      <div class="container-breadcrumb">
        <router-link class="text-breadcrumb" :to="{name: 'Home'}">Inicio</router-link>
        <span class="text-breadcrumb">{{ header.title }}</span>
      </div>
      <h1 class="container"> {{ header.title }} </h1>
    </div>
    <div class="container-body-politica">
      <auth-tratamientos v-if="isAuthTratamiento" />
      <proteccion-datos v-if="isPoliticaTratamiento" />
      <TyC v-if="isPolitica" />
      <auth-centrales v-if="isCentrales" />
      <politicas-credito v-if="isPoliticaCredit" />
      <aviso-de-privacidad v-if="isAvisoDePrivacidad" />
      <div v-if="isFrecuentQuestion" class="conatiner-preguntas-cupo">
        <div class="accordion-cupo" role="tablist">
          <b-card no-body :key="index" v-for="(item, index) in preguntasFrecuentes">
            <b-card-header header-tag="header" role="tab">
              <b-button block v-b-toggle="'accordion-' + index " variant="Secondary" class="d-flex w-100 align-items-center justify-content-between rounded-0 text-start">
                <b>{{item.titulo}}</b>
                <span class="ml-auto when-open float-right img-icon-acoordeon"><img src="@/assets/hidde-tablist.svg" alt=""></span>
                <span class="ml-auto when-closed float-right img-icon-acoordeon"><img src="@/assets/show-tablist.svg" alt=""></span>
              </b-button>
            </b-card-header>
            <b-collapse :id="'accordion-' + index " accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text style="color: black" v-html="item.descripcion">
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
    <!-- <breaking-nav/> -->
  </div>
</template>
<script>
import AuthCentrales from '@/components/politicas/AuthCentrales.vue'
import AuthTratamientos from '@/components/politicas/AuthTratamientos.vue'
import ProteccionDatos from '@/components/politicas/ProteccionDatos.vue'
import PoliticasCredito from '@/components/politicas/PoliticasCredito.vue'
import AvisoDePrivacidad from '@/components/politicas/AvisoDePrivacidad.vue'
import TyC from '@/components/politicas/TyC.vue'
import { mapActions, mapState, } from 'vuex'
export default {
  components: {
    AuthCentrales,
    AuthTratamientos,
    ProteccionDatos,
    TyC,
    PoliticasCredito,
    AvisoDePrivacidad,
  },
  data() {
    return {
      isPolitica: true,
      isPoliticaTratamiento: false,
      isAuthTratamiento: false,
      isCentrales: false,
      isFrecuentQuestion: false,
      isPoliticaCredit: false,
      isAvisoDePrivacidad: false,
      header: {
        title: 'Términos y condiciones',
        updatedAt: 'Última actualización 17 de mayo 2023',
      },
    }
  },
  async mounted() {
    this.getMarcas()
    if(this.$route.name == 'PreguntasFrecuntes') {
      this.isFrecuentQuestion = true
      this.isPolitica = false
      this.header = {
        title: 'Preguntas frecuentes',
        updatedAt: 'Última actualización 17 de mayo 2023',
      }
    } else if (this.$route.name == 'PoliticaTratamiento') {
      this.isPolitica = false
      this.isPoliticaTratamiento = true
      this.header = {
        title: 'Política de protección de datos personales - PTI',
        updatedAt: 'Última actualización 17 de mayo 2023',
      }
    } else if (this.$route.name == 'AuthTratamiento') {
      this.isPolitica = false
      this.isAuthTratamiento = true
      this.header = {
        title: 'Autorización tratamiento de datos',
        updatedAt: 'Última actualización 17 de mayo 2023',
      }
    } else if (this.$route.name == 'AuthConsulta') {
      this.isPolitica = false
      this.isCentrales = true
      this.header = {
        title: 'Autorización consulta centrales',
        updatedAt: 'Última actualización 16 de mayo 2023',
      }
    } else if (this.$route.name == 'AvisoDePrivacidad') {
      this.isPolitica = false
      this.isAvisoDePrivacidad = true
      this.header = {
        title: 'Aviso de Privacidad',
        updatedAt: 'Última actualización 19 de julio 2024',
      }
    } else if (this.$route.name == 'PoliticasCredito') {
      this.isPolitica = false
      this.isPoliticaCredit = true
      this.header = {
        title: 'Política de crédito',
        updatedAt: 'Última actualización 11 de junio 2024',
      }
    }
  },
  computed: {
    ...mapState('globalState', ['preguntasFrecuentes'])
  },
  methods: {
    ...mapActions('globalState', ['getMarcas']),
  },
}
</script>
<style lang="scss" scoped>
.container-page-politicas {
  position: relative;
  z-index: 10;
  .header-page-politicas {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    max-width: calc(100% - 30px);
    margin-right: auto;
    margin: auto;
    padding: 1rem;
    position: relative;
    z-index: 100;
    h1 {
      text-align: center;
      font-weight: bold;
      width: 80%;
      font-size: 40px;
      max-width: 1400px;
    }
    p {
      text-align: center;
      margin-bottom: 0;
      width: 100%;
    }
    .container-breadcrumb {
      display: flex;
      overflow: auto;
      justify-content: left;
      align-items: center;
      gap: 15px;
      margin-bottom: 2rem;
      width: 100%;
      .text-breadcrumb {
        color: #5E698B !important;
        position: relative;
        font-size: 16px;
        &::after {
          content: url('../assets/icons/icon-row-right.svg');
          position: absolute;
          height: 100%;
          right: -11px;
          top: 50%;
          transform: translateY(-50%);
        }
        &:last-child {
          font-weight: bold;
          color: black !important;
          &::after {
            content: '';
          }
        }
      }
      .buton-action-breadcrumb {
        background-color: #727997;
        height: 20px;
        padding: 5px;
        align-items: center;
        justify-content: center;
        display: flex;
        border: none;
        transition: all .2s linear;
        img {
          width: 14px;
          filter: brightness(2000%);
        }
        &:hover {
          background-color: #FFD947;
          img {
            filter: none;
          }
        }
      }
    }
  }
  .container-body-politica {
    padding: 1rem 0rem;
    padding-bottom: 5rem;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    &::before {
      content: url('../assets/decorador-politicas-1.svg');
      position: absolute;
    }
    &::after {
      content: url('../assets/decorador-politicas-2.svg');
      position: absolute;
      bottom: 0;
      right: 0;
    }

    h4 {
      font-size: 1.1rem;
    }

    .upper {
      text-transform: uppercase;
    }

    p {
      margin-bottom: 24px;
    }

    ul {
      margin-left: 0;
    }

    .container {
      width: 80%;
      max-width: 1400px;
      margin: 36px auto;
      position: relative;
      z-index: 1;
    }

    .etiqueta {
      font-family: Poppins;
      font-weight: 700;
    }

    img,
    svg {
      max-width: 100% !important;
    }

    thead tr {
      background: #7633FF;
      color: #f2f2f2;
    }

    .lead {
      font-weight: 600;
      text-align: center;
      line-height: 110%;
    }

    .title {
      font-weight: 700;
    }

    .purple {
      color: #321185;
    }
  }
}
</style>
